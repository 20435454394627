import { createRouter, createWebHashHistory } from 'vue-router'

function isAuthenticated() {
  return localStorage.getItem('usuario') !== null;
}

const routes = [

  //ROUTES FOR WEB
  {
    path: '/',
    name: 'web_index',
    component: () => import('../views/index.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/sobre-nos',
    name: 'web_about',
    component: () => import('../views/about.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/planos',
    name: 'web_plans',
    component: () => import('../views/plans.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/noticias',
    name: 'web_blog',
    component: () => import('../views/blog.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/servico/detalhes/:id/',
    name: 'servico_detalhes',
    component: () => import('../views/service-detail.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/noticias/detalhes/:id/',
    name: 'noticia_detalhes',
    component: () => import('../views/blog-detail.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/contactos',
    name: 'web_plans',
    component: () => import('../views/contact.vue'),
    meta: { requiresAuth: false } 
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('../views/404.vue'),
    meta: { requiresAuth: false } 
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// Guarda de navegação para verificar autenticação
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
