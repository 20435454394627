import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store'

const app = createApp(App).use(store).use(store)

app.use(router)
app.use(VueSweetalert2);
app.mount('#app')
